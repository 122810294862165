import React, { useEffect, useState } from 'react';
import AltinLogo from '../Static/img/a-logo-blue.svg';

const SuccessPage = () => {
  const [referenceID, setReferenceID] = useState('');

  useEffect(() => {
    // Generate a random reference ID
    const generateReferenceID = () => {
      return 'REF' + Math.floor(Math.random() * 1000000000).toString();
    };
    setReferenceID(generateReferenceID());
  }, []);

  const handleLogout = () => {
    window.location.href = 'https://info.altinn.no/nn/';
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="max-w-md w-full p-8 rounded-lg shadow-lg bg-white">
        <div className="flex flex-col items-center mb-6">
          <img src={AltinLogo} alt="Altin Logo" className="h-16 mb-4" />
          <h1 className="text-2xl font-bold mb-4 text-center">Vi har mottatt forespørselen din</h1>
          <p className="text-gray-700 mb-6 text-center">Vi vil snart kontakte deg angående bekreftelse.</p>
        </div>
        <div className="text-center mb-6">
          <p className="text-gray-700 font-semibold">Referanse-ID:</p>
          <p className="text-blue-500 font-bold">{referenceID}</p>
        </div>
        <div className="flex justify-center">
          <button
            onClick={handleLogout}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md focus:outline-none"
          >
            Logg ut
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
